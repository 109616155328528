:root {
  --ivory-color: hsla(60, 100%, 97%, 1);
  --black-color: hsla(360, 0%, 0%, 1);
  --white-color: hsla(360, 0%, 100%, 1);
  --beige-color: hsla(60, 56%, 91%, 1);
  --midnightblue-color: hsla(240, 64%, 27%, 1);
  --darkgreen-color: hsla(120, 100%, 20%, 1);
  --silver-color: hsla(360, 0%, 75%, 1);
  --indigo-color: hsla(275, 100%, 25%, 1);
  --blue-color: hsla(240, 100%, 50%, 1);
  --orangered-color: hsla(16, 100%, 50%, 1);
  --darkorangered-color: hsla(16, 100%, 44%, 1);
  --steelblue-color: hsla(207, 44%, 49%, 1);
  --steelblue-color-75: hsla(207, 44%, 49%, 0.75);
  --whitesmoke-color: hsla(360, 0%, 96%, 1);
  --limegreen-color: hsla(120, 61%, 50%, 1);
  --darkslate-color: hsla(210, 13%, 50%, 1);
  --darkslate-color-50: rgba(50, 109, 163, 0.5);
  --darkslate-color-25: rgba(50, 109, 163, 0.25);
  --royalblue-color: hsla(225, 73%, 57%, 1);
  --darkgray-color: hsla(360, 0%, 66%, 1);
  --lightgray-color: hsla(360, 0%, 83%, 1);
  --darkgray-color-50: hsla(360, 0%, 66%, 0.75);
  --floralwhite-color: hsla(40, 100%, 97%, 1);
  --yellowgreen-color: hsla(80, 61%, 50%, 1);
  --seegreen-color: hsla(146, 50%, 36%, 0.75);
  --cadetblue-color: hsla(182, 25%, 50%, 1);
  --powderblue-color: hsla(187, 52%, 80%, 1);
  --red-color: hsla(0, 100%, 50%, 1);

  --sk-red-color: #d32f2e;
  --sk-yellow-color: #fdc02a;
  --sk-green-color: #689f39;
}

.SK1 {
  background-image: linear-gradient(180deg, hsla(346, 97%, 36%, 1) 15%, hsla(346, 97%, 36%, 1) 75%);
}

.SK2 {
  background-image: linear-gradient(180deg, hsla(43, 98%, 58%, 1) 15%, hsla(43, 98%, 58%, 1) 75%);
}

.SK3 {
  background-image: linear-gradient(180deg, hsla(92, 47%, 42%, 1) 15%, hsla(92, 47%, 42%, 1) 75%);
}

.AVAILABLE {
  background-image: linear-gradient(180deg, hsla(129, 99%, 45%, 0.75) 15%, hsla(120, 98%, 36%, 0.75) 75%);
}

.NOT_AVAILABLE_WITHOUT_REDUNDANCY {
  background-image: linear-gradient(180deg, hsla(298, 98%, 31%, 0.75) 15%, hsla(283, 96%, 31%, 0.75) 75%);
}

.NOT_AVAILABLE {
  background-image: linear-gradient(180deg, hsla(360, 0%, 66%, 0.75) 15%, hsla(360, 0%, 66%, 0.75) 75%);
}

.NOT_ON_DUTY {
  background-image: linear-gradient(180deg, hsla(224, 88%, 52%, 0.75) 15%, hsla(231, 93%, 27%, 0.75) 75%);
}

.OVERLOAD {
  background-image: linear-gradient(180deg, hsla(348, 100%, 65%, 0.865) 15%, hsla(350, 99%, 41%, 0.831) 75%);
}

.DEFECT {
  background-image: linear-gradient(180deg, hsla(0, 1%, 23%, 0.75) 15%, hsla(0, 0%, 0%, 0.75) 75%);
}

.ALERT {
  background-image: linear-gradient(180deg, hsla(59, 89%, 58%, 0.75) 15%, hsla(50, 100%, 38%, 0.75) 75%);
}

/* Farben für das Mapping */
.FINE {
  background-image: linear-gradient(180deg, hsla(129, 100%, 39%, 0.75) 15%, hsla(120, 100%, 29%, 0.75) 75%);
}

.WARNING {
  background-image: linear-gradient(180deg, hsla(51, 100%, 50%, 0.75) 15%, hsla(51, 100%, 50%, 0.75) 75%);
}

.BAD {
  background-image: linear-gradient(180deg, hsla(348, 89%, 58%, 0.75) 15%, hsla(350, 100%, 38%, 0.75) 75%);
}

.INTIME {
  background-color: var(--darkgreen-color);
}

.OVERTIME {
  background-color: var(--darkorangered-color);
}

.NOT_ON_DUTY_TAG {
  background-color: #1422e675;
}

.DEFECT_TAG {
  background-color: #00000085;
}

.OVERRIDE_AVAILABLE_TAG {
  background: repeating-linear-gradient(135deg, #1422e675, #1422e675 10px, #699f3988 10px, #699f3988 20px);
}

.AVAILABLE_TAG {
  background-color: #699f3988;
}

.OVERLOAD_TAG {
  background-color: #d32e2e86;
}

.NOT_AVAILABLE_TAG {
  background-color: #a0a0a07e;
}

.OFFER {
  background-color: #4599b877;
}

.NO_OFFER {
  background-color: #4599b838;
}

.zlb-TransportTime {
  display: inline-block;
  border-radius: 3px 3px 3px 3px !important;
  font:
    normal 9pt Open Sans,
    sans-serif;
  text-align: center !important;
  line-height: 12px;
  color: var(--white-color);
}

.zlb-SKTinyBox {
  display: inline-block;
  border-radius: 3px 3px 3px 3px !important;
  font:
    normal 9pt Open Sans,
    sans-serif;
  text-align: center !important;
  line-height: 9pt;
  color: var(--white-color) !important;
  padding: 0.25rem;
}

/** ZLB Komponenten **/
.zlb-NoMessage {
  /** Keine Resultate **/
  font-weight: normal;
  font-family:
    Open Sans,
    sans-serif;
  font-size: 11pt;
  color: var(--steelblue-color);
  text-align: center !important;
}

.zlb-Status-xl {
  height: 18px;
  width: 18px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zlb-Status-2xl {
  height: 20px;
  width: 20px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zlb-Status-3xl {
  border-radius: 5px 5px 5px 5px;
  height: 26px;
  width: 26px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zlb-Status-4xl {
  border-radius: 5px 5px 5px 5px;
  height: 35px;
  width: 35px;
  min-width: 35px;
  line-height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zlb-Status-Typ {
  font:
    bold 9pt Open Sans,
    sans-serif;
  border-radius: 5px 5px 5px 5px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: var(--steelblue-color);
}

.zlb-CovidSmallNumber {
  /** Covidzahl für kleine Statusbox **/
  font:
    normal 9pt Open Sans,
    sans-serif;
  color: var(--ivory-color) !important;
}

.zlb-CovidMediumNumber {
  /** Covidzahl für kleine Statusbox **/
  font:
    bold 10pt Open Sans,
    sans-serif;
  color: var(--ivory-color) !important;
}

.zlb-CovidBigNumber {
  /** Covidzahl für große Statusbox */
  font:
    bold 16pt Open Sans,
    sans-serif;
  color: var(--ivory-color) !important;
}

.zlb-CovidKapazitaet {
  /** Covidkapazität **/
  font:
    normal 11pt Open Sans,
    sans-serif;
  color: var(--ivory-color) !important;
}

.zlb-OpenClose {
  color: var(--silver-color);
  font-weight: bold;
}

.zlb-DetailTable * {
  border: none !important;
}

.zlb-HeaderTable td {
  padding-right: 15px;
}

/** Texte **/
.zlb-BasicText {
  text-align: justify;
  font:
    normal 11pt Open Sans,
    sans-serif;
  line-height: 1.5rem !important;
  color: var(--darkslate-color) !important;
}

.zlb-BasicTextStrong {
  text-align: justify;
  font:
    bold 11pt Open Sans,
    sans-serif;
  line-height: 1.5rem !important;
  color: var(--darkslate-color) !important;
}

.zlb-DetailText {
  font:
    normal 10pt Open Sans,
    sans-serif;
  line-height: 1.3rem;
  color: var(--darkslate-color) !important;
  margin-left: 15px;
  vertical-align: middle !important;
}

.zlb-DetailTextStrong {
  font:
    bold 10pt Open Sans,
    sans-serif;
  line-height: 1.3rem;
  color: var(--darkslate-color) !important;
  margin-left: 15px;
}

/** Layout & Styling **/
div.zlb-Scroll-250 {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 250px;
  padding: 5px;
}

div.zlb-Scroll-350 {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 350px;
  padding: 5px;
}

div.zlb-Label {
  font:
    normal 10pt Open Sans,
    sans-serif;
  color: var(--darkslate-color);
  margin-top: 6px !important;
}

.zlb-StatusInlineTinyBox {
  /** Status: Winzig */
  height: 14px;
  width: 14px;
  line-height: 14px;
  display: inline-block;
}

.zlb-Checkbox {
  margin-bottom: 10px !important;
}

.zlb-CheckboxItem {
  margin-left: 6px;
}

.zlb-BottomSpaceTiny {
  margin-bottom: 6px;
}

.zlb-BottomSpaceSmall {
  margin-bottom: 12px;
}

.zlb-BottomSpaceMedium {
  margin-bottom: 22px;
}

.zlb-BottomSpaceLarge {
  margin-bottom: 32px;
}

.zlb-TopSpaceXXLarge {
  margin-top: 55px;
}

.zlb-TopSpaceXLarge {
  margin-top: 35px;
}

.zlb-TopSpaceLarge {
  margin-top: 25px;
}

.zlb-TopSpaceMedium {
  margin-top: 20px;
}

.zlb-TopSpaceSmall {
  margin-top: 10px;
}

.zlb-InputWidthMedium {
  width: 225px !important;
}

.zlb-SearchField {
  float: right;
}

.zlb-CardHeaderText {
  font-family:
    Open Sans,
    sans-serif;
  font-size: 12pt;
  font-style: normal;
  font-weight: normal;
  color: var(--ivory-color);
}

.zlb-CardNewsHeader {
  .p-card-title {
    color: var(--darkslate-color);
  }
}

.zlb-CardPhoneText {
  font:
    normal 10pt Open Sans,
    sans-serif;
  color: var(--darkslate-color) !important;
  margin-top: 9px;
  margin-left: 30px;
}

.zlb-CardEmailText {
  font:
    normal 10pt Open Sans,
    sans-serif;
  color: var(--darkslate-color) !important;
  margin-top: 10px;
  font-style: italic;
}

.zlb-FormTopLabel {
  margin-bottom: 6px;
  font:
    normal 10pt Open Sans,
    sans-serif;
  color: var(--darkslate-color) !important;
}

.zlb-FormBottomLabel {
  margin-top: 6px;
  font:
    normal 10pt Open Sans,
    sans-serif !important;
  color: var(--darkslate-color) !important;
}

.zlb-RadioButtonLabel {
  font:
    normal 11pt Open Sans,
    sans-serif !important;
  color: var(--darkslate-color) !important;
}

/** Following p-classes are adjustments to primeNG-dataTables **/
p-table {
  td,
  th {
    padding: 5px !important;
    font-family: Open, Sans, sans-serif;
  }
}

table:not(.table-dashboard) th {
  font-size: 0.9285rem;
}

table:not(.table-dashboard) td {
  font-size: 0.9285rem;
}

/** Tabellen

table th {
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Open, Sans, sans-serif;
  background-color: var(--steelblue-color);
  color: var(--whitesmoke-color);
  line-height: 1.2em;
  vertical-align: middle !important;
}

table td {
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Open, Sans, sans-serif;
  vertical-align: middle !important;
}

.p-datatable {
  .p-datatable-header {
    font-weight: normal;
    background-color: hsla(360, 0%, 92%, 1);
    color: var(--steelblue-color);
  }

  .p-datatable-thead>tr>th {
    font-weight: normal;
    background-color: var(--darkslate-color-50);
    color: var(--whitesmoke-color);
  }

  .p-sortable-column.p-highlight {
    font-weight: normal;
    background-color: var(--darkslate-color-50);
    color: var(--whitesmoke-color);
  }

  .p-sortable-column .p-sortable-column-icon {
    color: var(--whitesmoke-color);
  }

  .p-sortable-column .p-sortable-column-badge {
    background-color: var(--darkslate-color-50);
    color: var(--whitesmoke-color);
  }

  .p-sortable-column.p-highlight:hover {
    color: var(--steelblue-color-75);
  }

  .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: var(--steelblue-color-75);
  }

  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--whitesmoke-color);
  }

  .p-sortable-column:not(.p-highlight):hover {
    color: var(--steelblue-color-75);
  }

  .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: var(--steelblue-color-75);
  }
}
*/

.p-datatable table {
  table-layout: auto !important;
}

.zlb-tooltip .p-tooltip-text {
  background-color: hsl(2, 53%, 36%) !important;
  color: #fff !important;
  font:
    normal 10pt/12pt Open Sans,
    sans-serif;
  //width: auto;
}

.zlb-tooltip-tableau .p-tooltip-text {
  background-color: rgb(127, 118, 118) !important;
  color: #ece7e7 !important;
  font:
    normal 10pt/12pt Open Sans,
    sans-serif;
  //width: 14rem;
}

/* NG Prime Anpassungen */

.zlb-ng-switch-text span {
  font:
    normal 12pt Open Sans,
    sans-serif !important;
  margin: 0 12px 0 12px;
  color: var(--text-color);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #6c757d;
  padding: 1rem;
  font:
    normal 11pt Open Sans,
    sans-serif !important;
  text-decoration: none !important;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}

.p-checkbox .p-checkbox-box {
  width: 18px;
  height: 18px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 10px;
}

.zlb-ng-panel {
  .p-panel-title {
    font:
      normal 16pt/1rem Open Sans,
      sans-serif !important;
    color: var(--ivory-color);
  }

  .p-panel-header {
    background-color: var(--steelblue-color);
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #edf1f7;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}

.zlb-ng-card {
  .p-card {
    .p-card-body {
      background-color: var(--white-color);
      padding: 0px 0px 0px 0px;
    }

    .p-card-title {
      background-color: var(--steelblue-color);
      color: var(--ivory-color);
      font:
        normal 11pt Open Sans,
        sans-serif;
      padding: 0.5rem;
      margin-bottom: 0rem;
    }

    .p-card-content {
      font:
        normal 10pt/1.2rem Open Sans,
        sans-serif;
      padding-left: 1.5rem;
      padding-right: 1rem;
    }
  }
}

.zlb-ng-subpanel {
  .p-panel-title {
    font:
      normal 11pt/0.5rem Open Sans,
      sans-serif !important;
    color: var(--ivory-color);
  }

  .p-panel-header {
    background-color: var(--steelblue-color-75);
  }

  .p-panel-header-icon {
    width: 28px !important;
    height: 28px !important;
  }

  .pi {
    font-size: 0.75rem !important;
  }
}

.zlb-badgeSecondary {
  .p-badge {
    background-color: lightgray !important;
    color: hsla(0, 0%, 0%, 0.75);
  }
}

.zlb-badgeAlarm {
  .p-badge {
    background-color: hsla(182, 25%, 50%, 1) !important;
    color: var(--ivory-color) !important;
    font:
      normal 9pt/1.5rem Open Sans,
      sans-serif !important;
  }
}

.zlb-avatar.AVAILABLE {
  .p-avatar {
    background-color: hsl(129, 83%, 36%);
  }
}

.zlb-avatar.OVERLOAD {
  .p-avatar {
    background-color: hsl(0, 94%, 49%);
  }
}

.zlb-avatar.NOT_ON_DUTY {
  .p-avatar {
    background-color: hsl(242, 80%, 47%);
  }
}

.zlb-avatar.DEFECT {
  .p-avatar {
    background-color: hsl(0, 100%, 0%);
  }
}

.zlb-avatar.NOT_AVAILABLE_WITHOUT_REDUNDANCY {
  .p-avatar {
    background-color: hsl(0, 0%, 87%);
  }

  .p-avatar-text {
    font:
      normal 11pt Open Sans,
      sans-serif;
    color: rgb(91, 90, 90);
  }
}

.zlb-avatar {
  background-image: unset;

  .p-avatar-text {
    font:
      normal 10pt Open Sans,
      sans-serif;
    color: rgb(255, 255, 255);
  }

  //Bug in current primeNG version, badgeDisabled condition is not working
  //see: https://github.com/primefaces/primeng/issues/12736
  .p-badge-dot {
    display: none;
  }
}

.zlb-avatar.AVAILABLE.OVERRIDE {
  //Bug in current primeNG version, badgeDisabled condition is not working
  //see: https://github.com/primefaces/primeng/issues/12736
  .p-badge-dot {
    display: inline-block;
    min-width: 0.5rem;
    height: 0.5rem;
  }
}

.zlb-chip {
  background-image: unset;

  .p-chip {
    background-color: #f5f5f5;
    color: #dcdcdc;
    font:
      normal 10pt Open Sans,
      sans-serif;
  }
}

.zlb-chip.active {
  .p-chip {
    background-color: #a9a9a9;
    color: #fffff0;
    font:
      normal 10pt Open Sans,
      sans-serif;
  }
}

.zlb-message {
  .p-inline-message-error {
    background: unset;
    color: var(--white-color);
  }

  .p-inline-message {
    .p-inline-message-text {
      font:
        normal 1.25rem Open Sans,
        sans-serif;
      margin-right: 2rem;
    }

    .p-inline-message-icon {
      color: var(--ivory-color);
      margin-left: 2rem;
    }
  }

  @media (min-width: 500px) and (max-width: 570px) {
    .p-inline-message {
      .p-inline-message-text {
        margin-right: 1rem;
      }

      .p-inline-message-icon {
        margin-left: 1rem;
      }
    }
  }

  @media (max-width: 500px) {
    .p-inline-message {
      .p-inline-message-text {
        margin-right: 0;
      }

      .p-inline-message-icon {
        margin-left: -0.5rem;
      }

      .p-inline-message-icon::before {
        content: none !important;
      }
    }
  }
}

.zlb-note {
  .p-inline-message {
    .p-inline-message-text {
      font:
        normal 0.85rem/1.2rem Open Sans,
        sans-serif !important;
    }
  }
}

.zlb-small-input-switch {
  .p-inputswitch {
    height: 1.2rem;
    width: 2.4rem;

    &.p-inputswitch-checked {
      .p-inputswitch-slider::before {
        transform: translateX(1rem);
      }
    }

    .p-inputswitch-slider::before {
      width: 0.9rem;
      height: 0.9rem;
      margin-top: -0.45rem;
    }
  }
}

/** PrimeNG - Einstellungen */

///** Accordion */
//.p-accordion-header-link {
//  text-decoration: none !important;
//  font-family:
//    Open Sans,
//    sans-serif !important;
//  font-size: 11pt !important;
//  font-weight: normal !important;
//  color: var(--ivory-color) !important;
//  background-color: var(--steelblue-color-75) !important;
//  padding: 0.5rem !important;
//}

/** Scrollpane */
.p-scrollpanel {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--layer-1);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }
}

.inputnumber-remove-padding {
  span {
    span {
      button {
        padding: unset !important;
      }
    }
  }
}

.p-timeline-event-opposite {
  flex: 0;
}

.w-3-5rem {
  width: 3.5rem;
}
