.layout-dark-topbar {
  --topbar-bg-color: #343d4a;
  --topbar-border-color: #545b67;
  --topbar-box-shadow: none;

  --topbar-item-text-color: #e9e9e9;
  --topbar-item-text-color-secondary: #d2d2d2;
  --topbar-input-bg-color: #656b76;

  --topbar-popup-item-bg-color: #343d4a;
  --topbar-popup-item-shadow: 0 5px 12px 6px #00000017;
}
