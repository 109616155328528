$primaryColor: #009775 !default;
$primaryLightColor: #00b58c !default;
$primaryLighterColor: rgba($primaryLightColor, 0.1);
$primaryDarkColor: #008063 !default;
$primaryDarkerColor: #006951 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: $primaryLighterColor !default;
$highlightTextColor: $primaryColor !default;
@import '../_variables';
@import '../../theme-base/_components';
@import '../_extensions';
