.card {
  background: var(--surface-card);
  box-sizing: border-box;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  border-radius: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.15rem;

    h6 {
      margin-bottom: 2px;
    }

    .subtitle {
      font-weight: 600;
      color: var(--text-color-secondary);
    }
  }

  .card-subtitle {
    color: var(--text-color-secondary);
    font-weight: 600;
  }

  &.no-gutter {
    margin-bottom: 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 70px;
  }
}

.ng-hidden {
  display: none !important;
}
