.layout-sidebar {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 16rem;
  background: var(--menu-bg);
  border-right: var(--sidebar-border);
  box-shadow: var(--sidebar-shadow);
  display: flex;
  flex-direction: column;

  .sidebar-header {
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .app-logo {
      .app-logo-normal {
        display: inline;
        height: 2rem;
        width: auto;
      }

      .app-logo-single {
        display: none;
      }
    }
  }

  .layout-menu-container {
    overflow: auto;
    flex: 1;
    padding-bottom: 2rem;
  }

  .layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .layout-root-menuitem {
      > .layout-menuitem-root-text {
        font-size: 0.857rem;
        text-transform: uppercase;
        font-weight: 700;
        color: var(--root-menuitem-text-color);
        padding: 1rem 2rem;
        padding-right: 1.25rem;
      }

      > a {
        display: none;
      }
    }

    a {
      user-select: none;

      &.active-menuitem {
        > .layout-submenu-toggler {
          transform: rotate(-180deg);
        }
      }
    }

    li.active-menuitem {
      > a {
        .layout-submenu-toggler {
          transform: rotate(-180deg);
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      a {
        display: flex;
        align-items: center;
        position: relative;
        outline: 0 none;
        color: var(--menuitem-text-color);
        cursor: pointer;
        padding: 0.75rem 2rem;
        padding-right: 1.25rem;
        transition: all var(--transition-duration);

        .layout-menuitem-icon {
          margin-right: 0.5rem;
          color: var(--menuitem-icon-color);
        }

        .layout-submenu-toggler {
          font-size: 75%;
          margin-left: auto;
          transition: transform var(--transition-duration);
        }

        &.active-route {
          color: var(--primary-color);

          i {
            color: var(--primary-color);
          }
        }

        &:hover {
          background-color: var(--menuitem-hover-bg-color);

          i {
            color: var(--primary-600);
          }

          color: var(--primary-600);
        }
      }

      ul {
        overflow: hidden;
        border-radius: var(--border-radius);

        li {
          a {
            padding-left: 2.5rem;
          }

          li {
            a {
              padding-left: 3rem;
            }

            li {
              a {
                padding-left: 3.5rem;
              }

              li {
                a {
                  padding-left: 4rem;
                }

                li {
                  a {
                    padding-left: 5.5rem;
                  }

                  li {
                    a {
                      padding-left: 5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
