.layout-breadcrumb {
  padding: 0 0 2rem;
  ol {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 1rem;
    flex-wrap: wrap;
    color: var(--text-color-secondary);

    li {
      font-weight: 500;
    }
  }
}
