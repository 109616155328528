/* PrimeNG */
@import 'primeng/resources/primeng.css';
@import 'primeflex/primeflex.scss';
@import 'primeicons/primeicons.css';

/* Openlayers */
@import 'ol/ol.css';

/* Quill */
//@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

/* Freya */
@import 'layout/styles/layout/layout.scss';
@import 'layout/styles/theme/theme-light/fraunhofer/theme.scss';

/* Customizations */
@import 'layout/styles/zlb-classes';
